import { Injectable } from '@angular/core';
import { HttpResponseData } from '../classes/http-communication';
import { PermissionString } from '../classes/model/permissions';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { DataCacherService } from './data-cacher-service';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersBasicInfoService implements DataCacherService {
  private readonly adminUsersBasicInfosApiUrlFragment:string = "/api/admin/admins/names";

  private adminsBasicInfos:Array<AdminBasicInfo>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {
    this.adminsBasicInfos = new Array<AdminBasicInfo>();
  }

  public getAdminsBasicInfosArrayRef():ReadonlyArray<AdminBasicInfo> {
    return this.adminsBasicInfos;
  }

  public async fetchAdminUsersBasicInfos():Promise<Array<AdminBasicInfo>> {
    try {
      const response:HttpResponseData<Array<AdminBasicInfo>> = await this.backendService.callApi(this.adminUsersBasicInfosApiUrlFragment, "GET");

      return response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az adminisztrátorok nevének lekérdezése közben.");
    }

    return this.adminsBasicInfos;
  }

  public async loadDataIntoCache(): Promise<void> {
    const adminUsersBasicInfos:Array<AdminBasicInfo> = await this.fetchAdminUsersBasicInfos();
    this.adminsBasicInfos.replaceArrayElements(adminUsersBasicInfos);
  }

  public getRequiredPermissionsForDataLoading(): readonly PermissionString[] {
    return this.requiredPermissionsForDataLoading;
  }

  public clearCachedData():void {
    this.adminsBasicInfos.length = 0;
  }

  public getNameOfCachedData(): string {
    return "Adminisztrátorok";
  }

}

export type AdminBasicInfo = {
  uuid:string,
  name:string
  email:string;
};
