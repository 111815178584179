import { Injectable } from '@angular/core';
import { PermissionString } from '../../classes/model/permissions';
import { User } from '../../classes/user';

export class UserSession {
  uuid:string;
  name:string;
  email:string;
  roleNames:Array<string>;
  displayedRoleNames:Array<string>;
  permissions:Array<PermissionString>;
  sessionId:string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public static readonly CookieUserUuidKey:string = "UserUuid";
  public static readonly CookieUserNameKey:string = "UserNameKey";
  public static readonly CookieUserEmailKey:string = "UserEmailKey";
  public static readonly CookieUserRoleNamesKey:string = "UserRoleNameKey";
  public static readonly CookieUserDisplayedRoleNamesKey:string = "UserDisplayedRoleNameKey";
  public static readonly CookieUserSessionIdKey:string = "SessionIdKey";
  public static readonly CookieUserPermissionsKey:string = "UserPermissionsKey";

  public userSession:UserSession|null = null;

  constructor() {
    this.userSession = this.createUserSessionFromCookies();
  }

  public createLocalSession(user:User):void {
    localStorage.setItem(SessionService.CookieUserUuidKey, user.uuid);
    localStorage.setItem(SessionService.CookieUserNameKey, user.name ?? "Ismeretlen felhasználó");
    localStorage.setItem(SessionService.CookieUserEmailKey, user.email);
    localStorage.setItem(
      SessionService.CookieUserRoleNamesKey,
      JSON.stringify(user.roleNames ?? []),
    );
    localStorage.setItem(
      SessionService.CookieUserDisplayedRoleNamesKey,
      JSON.stringify(user.displayedRoleNames ?? [])
    );
    localStorage.setItem(SessionService.CookieUserSessionIdKey, user.sessionID);
    localStorage.setItem(
      SessionService.CookieUserPermissionsKey,
      JSON.stringify(user.permissions ?? [])
    );

    this.userSession = this.createUserSessionFromCookies();
  }

  public deleteLocalSession():void {
    localStorage.removeItem(SessionService.CookieUserUuidKey);
    localStorage.removeItem(SessionService.CookieUserNameKey);
    localStorage.removeItem(SessionService.CookieUserEmailKey);
    localStorage.removeItem(SessionService.CookieUserRoleNamesKey);
    localStorage.removeItem(SessionService.CookieUserDisplayedRoleNamesKey);
    localStorage.removeItem(SessionService.CookieUserSessionIdKey);
    localStorage.removeItem(SessionService.CookieUserPermissionsKey);
    this.userSession = null;
  }

  public isThereLocalSession():boolean {
    return this.userSession !== null;
  }

  public getUuid():string|null {
    return this.userSession?.uuid;
  }

  public getNameOfLoggedUser():string|null {
    return this.userSession?.name;
  }

  public getEmail():string {
    return this.userSession?.email;
  }

  public getRoleNames():Array<string>|null {
    return this.userSession?.roleNames;
  }

  public getDisplayedRoleNames():Array<string>|null {
    return this.userSession?.displayedRoleNames;
  }

  public getSessionId():string|null {
    return this.userSession?.sessionId;
  }

  public getPermissions():Array<PermissionString>|null {
    return this.userSession?.permissions ?? [];
  }

  public updatePermissions(permissions:Array<PermissionString>):void {
    this.userSession.permissions.replaceArrayElements(permissions);

    localStorage.setItem(
      SessionService.CookieUserPermissionsKey,
      JSON.stringify(permissions ?? [])
    );
  }

  private createUserSessionFromCookies():UserSession|null {
    if(this.isThereSavedSessionInCookies()) {
      return {
        uuid:               localStorage.getItem(SessionService.CookieUserUuidKey),
        name:           localStorage.getItem(SessionService.CookieUserNameKey),
        email:              localStorage.getItem(SessionService.CookieUserEmailKey),
        roleNames:          JSON.parse(localStorage.getItem(SessionService.CookieUserRoleNamesKey) ?? "[]"),
        displayedRoleNames: JSON.parse(localStorage.getItem(SessionService.CookieUserDisplayedRoleNamesKey) ?? "[]"),
        sessionId:          localStorage.getItem(SessionService.CookieUserSessionIdKey),
        permissions:        JSON.parse(localStorage.getItem(SessionService.CookieUserPermissionsKey) ?? "[]")
      }
    } else {
      return null;
    }
  }

  private isThereSavedSessionInCookies():boolean {
    return !!localStorage.getItem(SessionService.CookieUserUuidKey) &&
           !!localStorage.getItem(SessionService.CookieUserNameKey) &&
           !!localStorage.getItem(SessionService.CookieUserEmailKey) &&
           !!localStorage.getItem(SessionService.CookieUserRoleNamesKey) &&
           !!localStorage.getItem(SessionService.CookieUserDisplayedRoleNamesKey) &&
           !!localStorage.getItem(SessionService.CookieUserSessionIdKey) &&
           !!localStorage.getItem(SessionService.CookieUserPermissionsKey);
  }

}
