import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SessionService } from 'src/app/services/common/session.service';
import { AuthenticationService } from 'src/app/services/common/authentication.service';
import { Settings, SettingsService } from 'src/app/services/common/settings.service';
import { EnvironmentService } from 'src/app/services/common/environments/environment.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild("hamburgerContainer") hamburgerContainer:ElementRef<HTMLDivElement>;

  name:string;
  displayedRoles:Array<string>;

  isNavigationSectionVisible:boolean;

  constructor(
    protected sessionService:SessionService,
    private authenticationService:AuthenticationService,
    private settingsService:SettingsService,
    protected environmentService:EnvironmentService
  ) { }

  ngOnInit(): void {
    this.name = this.sessionService.getNameOfLoggedUser();
    this.displayedRoles = this.sessionService.getDisplayedRoleNames();
    this.isNavigationSectionVisible = this.settingsService.getSettingsValue<boolean>(Settings.isNavigatonSectionVisible, true);
  }

  /**
   * Handles the click on the logout button.
   *
   * @returns {void} nothing
  */
  async handleLogoutButtonClick():Promise<void> {
    await this.authenticationService.logout();
  }

  public toogleNavigationSectionVisibility():void {
    this.isNavigationSectionVisible = !this.isNavigationSectionVisible;
    this.settingsService.setSettingsValue<boolean>(Settings.isNavigatonSectionVisible, this.isNavigationSectionVisible);
  }

}

