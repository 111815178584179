import { GiftedPackage } from './packages/GiftedPackage';
import { PermissionString } from './permissions';

export class AdminUser {
  uuid:string;
  name:string;
  email:string;
  roleNames:Array<string>;
  handedOutGifts:Array<GiftedPackage>;
  permissions:Array<PermissionString>;
}

export type AdminRole = {
	name:string;
	displayedName:string;
	basePermissions:Array<PermissionString>;
  description?:string;
}
