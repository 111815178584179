import { PermissionString } from './model/permissions';

export class UserLoginObject {
    credentials:{
        email:string;
        password:string;
    }
}

export class User {
    uuid:string;
    name:string;
    email:string;
    sessionID:string;
    roleNames:Array<string>;
    displayedRoleNames:Array<string>;
    permissions:Array<PermissionString>;
}

export function adminUserSorterByName(adminUser1:Partial<User>, adminUser2:Partial<User>) {
    return (adminUser1.name ?? "").localeCompare(adminUser2.name ?? "");
}

