import { Component, Input, OnInit } from '@angular/core';
import { Permission } from 'src/app/classes/model/permissions';
import { AdminBasicInfo, AdminUsersBasicInfoService } from 'src/app/services/admin-users-basic-info.service';
import { PermissionService } from 'src/app/services/common/permission.service';
import { SessionService } from 'src/app/services/common/session.service';
import { EntityComment } from '../../../models/entity-comment';
import { CommentsOnEntityModuleService } from '../../../services/comments-on-entity.service';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent implements OnInit {
  @Input() entityComment:EntityComment

  isOwnComment:boolean;
  isUserServerManager:boolean;

  adminName:string;

  constructor(
    private sessionService:SessionService,
    private permissionService:PermissionService,
    private commentsOnEntityModuleService:CommentsOnEntityModuleService,
    private adminUsersBasicInfoService:AdminUsersBasicInfoService
  ) { }

  public ngOnInit(): void {
    // Stores that is the comment is belongs to the actual admin user
    this.isOwnComment = this.entityComment.created.adminEmail === this.sessionService.getEmail();

    // Stores that the actual admin has the server manager permission
    this.isUserServerManager = this.permissionService.isLoggedUserHasPermission(Permission.ServerManager);

    // Resolved admin name
    this.adminName = this.adminUsersBasicInfoService.getAdminsBasicInfosArrayRef().find(
      (adminBasicInfo:AdminBasicInfo) => {
        return adminBasicInfo.email === this.entityComment.created.adminEmail;
      }
    )?.name ?? "Ismeretlen adminisztrátor";
  }

  /**
   * Tooggles the given comment's visibility.
   *
   * @param isVisible the new value for visibility
   */
  public async toolgeMessageVisibility(isVisible:boolean):Promise<void> {
    await this.commentsOnEntityModuleService.updateCommentOnEntityVisibility(
      this.entityComment.uuid,
      isVisible
    );
  }
}
